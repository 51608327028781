



















































































































































































































































import { mapGetters } from 'vuex'
import { successNotification } from '@/lib/calendesk-js-library/tools/notification'
import CColorPicker from '@/lib/calendesk-js-library/components/CColorPicker.vue'
import mixins from 'vue-typed-mixins'
import PlanActions from '@/builder/mixins/PlanActions'
import { pushEvent } from '@/lib/calendesk-js-library/tools/helpers'

export default mixins(PlanActions).extend({
  name: 'AddCodeToWebsite',
  components: { CColorPicker },
  data () {
    return {
      currentMode: 1,
      showCode: false,
      buttonText: null,
      textColor: '#ffffff',
      backgroundColor: '#4650c2',
      frameHeightDesktop: 900,
      frameHeightMobile: 1440
    }
  },
  computed: {
    ...mapGetters({
      domain: 'domain/getDefaultDomain',
      appConfiguration: 'setup/getAppConfiguration'
    }),
    getFullDomain (): string | null {
      if (this.domain && this.domain.domain) {
        return `https://${this.domain.domain}/`
      }

      return null
    },
    baseScript (): string {
      return '<!-- Calendesk widget begin -->\n<' + 'link href="https://media.calendesk.com/external/widget/v3/widget.css" rel="stylesheet">\n' +
        '<' + 'script src="https://media.calendesk.com/external/widget/v3/widget.js" type="text/javascript"' + '>' + '<' + '/script>'
    },
    getHeadCode (): string {
      if (this.currentMode === 1) {
        return `<!-- Calendesk frame CSS begin -->\n<style>.calendesk-frame {width: 100%;display: block;border:none;max-width: 1280px;margin: 0 auto;}@media only screen and (max-width:600px) {.calendesk-frame {height: ${this.frameHeightMobile}px;}}@media only screen and (min-width: 600px) {.calendesk-frame {height: ${this.frameHeightDesktop}px;}}</style>\n<!-- Calendesk frame CSS end -->`
      }

      return this.baseScript + '\n<!-- Calendesk widget end -->'
    },
    getCode (): string {
      if (this.currentMode === 1) {
        return `<!-- Calendesk frame begin -->\n<iframe class="calendesk-frame" src="${this.getFullDomain}" scrolling="yes" title="${this.appConfiguration.companyName}" frameBorder="0"></iframe>\n<!-- Calendesk frame end -->`
      } else if (this.currentMode === 2) {
        return this.baseScript +
          '<' + 'script type="text/javascript"' + '>' +
          `Calendesk.initBadgeWidget({ url: '${this.getFullDomain}', text: '${this.buttonText}', color: '${this.backgroundColor}', textColor: '${this.textColor}' });` + '<' + '/script>\n<!-- Calendesk widget end -->'
      }

      return `<!-- Calendesk widget begin -->\n<a href="" onclick="Calendesk.openModal({ url: '${this.getFullDomain}' });return false;">${this.buttonText}</a>\n<!-- Calendesk widget end -->`
    }
  },
  created () {
    pushEvent('wbAddCodeToWebsite')
    this.buttonText = this.$trans('widget_dummy_1')
  },
  methods: {
    nextStep () {
      if (this.widgetsAvailable) {
        this.showCode = true
      } else {
        this.openFeatureNotAvailableDialog()
      }
    },
    copied () {
      this.$helpers.copyToClipboard(this.getCode)
      successNotification('copied_to_clipboard')
    },
    copiedHead () {
      this.$helpers.copyToClipboard(this.getHeadCode)
      successNotification('copied_to_clipboard')
    }
  }
})

